export const hero = {
  title: "Manage your entire business from one back-of-house platform",
  subTitle: "Back-of-house management",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/back-of-house-management/demo",
  },
};

export const heroImage = {
  imageName: "products-house-management-hero.png",
};

export const secondaryHeroTwoCols = {
  title: "Instant view of your operations",
  subtext:
    "Access your business data on any device from a centralized dashboard view that shows average order size, total number of orders, most popular menu items, the highest revenue drivers, the day’s live sales, and more.",
  image: {
    imageName: "products-house-management-secondary-hero-2.png",
    mobileImageName: "products-house-management-secondary-hero-2-mobile.png",
    title: "We’re dedicated to your success",
    subtext: "BACK-OF-HOUSE MANAGEMENT",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/back-of-house-management/demo",
  },
};

export const productOverview = {
  subSections: [
    {
      title: "Manage at an enterprise-level or a specific location",
      subtext: `With hierarchical controls, it’s easy to drive menu assignment, pricing, and permissions, and make changes across thousands of devices or locations instantly, from one centralized location.`,
      imageName: "products-house-management-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/back-of-house-management/demo",
      },
    },
    {
      title: "Never run out of stock with real-time inventory control",
      subtext: `Manage inventory in real-time from anywhere with updates, alerts, and even front-of-house countdowns that ensure your staff never sells sold-out menu items. Plus, with an inventory API and third-party integrations, you can continue to operate business as usual with your current partners.`,
      imageName: "products-house-management-product-2.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/back-of-house-management/demo",
      },
    },
  ],
};

export const highlightWithImage = {
  title: "Connect the kitchen to the front-of-house",
  subtext: `Reduce spoilage and get orders to the front-of-house faster with real-time order information from POS devices to the Kitchen Display System (KDS). With a simplified snapshot overview, back-of-house staff will get items to the expediter faster than ever before.`,
  imageName: "products-house-management-product-3.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/back-of-house-management/demo",
  },
};

export const productOverview2 = {
  subSections: [
    {
      title: "Fast reports on everything you need",
      subtext: `Sharpen your perspective on both the big picture and day-to-day operations with robust reporting into what your guests are ordering, what locations are high-performers, and which cashiers are making the most sales.`,
      imageName: "products-house-management-product-4.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/back-of-house-management/demo",
      },
    },
  ],
};

export const productSlides = {
  title: "An inside look at your enterprise management platform",
  slides: [
    {
      title: "All-in-one dashboard view",
      subtext:
        "See how the business is performing in real time, via a simple, streamlined dashboard view. From there, take a deeper dive into specific key performance indicators by location or enterprise-wide.",
      imageName: "products-house-management-slides-1.png",
    },
    {
      title: "Live insights into your business",
      subtext:
        "Track sales and stock levels in real-time, manage inventory across all cashier-facing and/or guest-facing devices, and break down sales and payment types by location with advanced reporting and analytics.",
      imageName: "products-house-management-slides-2.png",
    },
    {
      title: "Centralized management across all location",
      subtext:
        "Manage all aspects of your omnichannel sales operations from one cloud-based, centralized dashboard. Make changes across thousands of location instantly or override enterprise items and adjust settings on a location level.",
      imageName: "products-house-management-slides-3.png",
    },
    {
      title: "Make more informed, data-driven decisions",
      subtext:
        "Easily access more in-depth reporting and data needed to run your operations and analyze performance. Leverage our detailed and robust reporting to perform product mix analysis, inventory and labor forecasting, fraudulent activity tracking, and more.",
      imageName: "products-house-management-slides-4.png",
    },
    {
      title: "Intelligent vending solutions",
      subtext:
        "Effectively manage hawker transactions and track inventory and products issued in real time. Generate sales and commission reports featuring unlimited commission structures and hawker type tiers.",
      imageName: "products-house-management-slides-5.png",
    },
    {
      title: "Seamless integrations",
      subtext:
        "SpotOn’s API enables us to integrate your system with over 80 third-party solutions for further control over loyalty and member benefits, external and stored value, inventory management, liquor control systems, digital menu boards, labor management, mobile ordering, education, ticketing, and more.",
      imageName: "products-house-management-slides-6.png",
    },
  ],
};

export const productsWithLinks = {
  title: "Built on a true omnichannel ecosystem",
  products: [
    {
      title: "Point-of-Sale",
      description:
        "Reduce wait times with a user-friendly POS solution that processes orders and payments fast, even when offline.",
      linkTo: "/enterprise/pos-terminals",
      imageName: "products-kiosk.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/back-of-house-management/demo",
  },
};
