import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  heroImage,
  secondaryHeroTwoCols,
  productOverview,
  highlightWithImage,
  productOverview2,
  productSlides,
  productsWithLinks,
  footer,
} from "../../../data/appetize/back-of-house-management";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImageFullWidth = loadable(() =>
  import("../../../components-v2/Sections/LargeImageFullWidth")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const ProductOverview = loadable(() =>
  import("../../../components-v2/Sections/ProductOverview")
);
const ProductSlides = loadable(() =>
  import("../../../components-v2/Sections/ProductSlides")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);
const HighlightWithImageAndOffsetText = loadable(() =>
  import("../../../components-v2/Sections/HighlightWithImageAndOffsetText")
);

const BackOfHouseManagement = () => {
  return (
    <Layout className="non-fixed-width" {...enterpriseSupport}>
      <SEO
        loadChat={false}
        title="Back of the House Restaurant Operations Software | SponOn"
        description={hero.title}
        cannonical="https://spoton.com/enterprise/back-of-house-management/"
      />
      <SimpleHero className="mt-32 lg:mt-40" {...hero} />

      <LargeImageFullWidth className="mt-10 md:mt-4 lg:mt-0" {...heroImage} />

      <SecondaryHeroTitleTwoCols
        className="mt-12 lg:mt-28"
        {...secondaryHeroTwoCols}
      />

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview}
        noMainTitle
        offsetText
        flipItems
      />

      <HighlightWithImageAndOffsetText
        className="mt-20 lg:mt-28"
        {...highlightWithImage}
      />

      <ProductOverview
        className="mt-14 lg:mt-40"
        {...productOverview2}
        noMainTitle
        offsetText
        rightBackground
      />

      <ProductSlides className="mt-20 lg:mt-36" {...productSlides} />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />
    </Layout>
  );
};

export default BackOfHouseManagement;
